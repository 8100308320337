/* eslint-disable complexity */

(function ($) {
  Drupal.behaviors.basicLandscapeToutV1 = {
    selector: {
      image: '.js-tout-block-landscape__image'
    },

    attach: function (context) {
      var self = this;

      if (self.attached) {
        return;
      }
      self.attached = true;
      self.setup(context);
    },

    setup: function (context) {
      var self = this;
      var $images = $(self.selector.image, context);

      $images.each(function () {
        var $img = $(this);
        var img = new Image();

        img.src = $img.attr('src');
        img.onload = function () {
          $img.attr('width', img.naturalWidth);
          $img.attr('height', img.naturalHeight);
        };
      });
    },

    attached: false
  };
})(jQuery);
